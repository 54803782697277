import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="flex flex-col items-center justify-center">
        <div className="mx-6 my-16 sm:max-w-screen-sm">
          {/* Title */}
          <div className="text-5xl font-bold">YUTA OKAJIMA</div>

          {/* Lead */}
          <div className="mt-12 text-3xl font-bold">
            is a product designer / design technologist who crafts
            human-centered products from both UX and code.
          </div>

          {/* Descriptions */}
          <div className="mt-16 flex flex-col space-y-4">
            <div>
              As a product designer / design technologist experienced various
              industries such as eCommerce, Entertainment, Consumer Electronics,
              Video Streaming, Cruise, Hotel, and Automotive in the U.S. and
              Japan for more than 18 years.
            </div>
            <div>
              In those experiences, there was always a big gap between design
              and development. So to build a well-crafted product, you need
              someone who fills the gap. That is why I love not only designing
              but also coding.
            </div>
          </div>

          {/* Links */}
          <ul className="mt-16 flex flex-col space-y-1 text-sm">
            <li>Bellevue, WA, USA</li>
            <li>
              <a href="https://kalc.app/">kalc.app</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/yutaok/">
                linkedin.com/in/yutaok
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/yutaok/">
                instagram.com/yutaok
              </a>
            </li>
            <li>
              <a href="https://github.com/yutaok">github.com/yutaok</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
